import { ReactNode } from 'react'

import Head from '@/components/core/Head'

interface Props {
  readonly children: ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <div className="page">
      <Head />
      <div className="page__wrap">
        <div className="page__content">{children}</div>
      </div>
    </div>
  )
}

export default Layout
