import { FunctionComponent, ReactComponentElement } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/core/Button/Button'
import { InputField } from '@/components/forms/Input/InputField'
import Cell from '@/components/layout/Grid/Cell.Styled'
import Grid from '@/components/layout/Grid/Grid.Styled'
import { useAuth } from '@/hooks/useAuthProvider'
import { useToastMessages } from '@/hooks/useToastMessages'

type FormValues = {
  email: string
  password: string
}

export const LoginForm: FunctionComponent =
  (): ReactComponentElement<'form'> => {
    const { t } = useTranslation()
    const { showToast } = useToastMessages()
    const { control, handleSubmit } = useForm<FormValues>()
    const { loading, signIn } = useAuth()

    const onSubmit = handleSubmit(async (data) => {
      try {
        await signIn(data.email, data.password)
      } catch (error) {
        showToast({
          message: error as string,
          type: 'error',
        })
      }
    })

    return (
      <form
        noValidate
        onSubmit={onSubmit}
        style={{ width: '450px', marginTop: '10px' }}
      >
        <Grid columns={1} rows={3}>
          <Cell width={12}>
            <InputField
              control={control}
              name="email"
              placeholder={t('email_address')}
              rules={{
                required: t('error_invalid_email'),
              }}
              type="email"
              variant="filled-gray"
              variantSize="lg"
            />
          </Cell>
          <Cell width={12}>
            <InputField
              control={control}
              name="password"
              placeholder={t('password')}
              rules={{
                required: t('profile_password'),
              }}
              type="password"
              variant="filled-gray"
              variantSize="lg"
            />
          </Cell>

          <Cell width={12}>
            <Button
              type={'submit'}
              loading={loading}
              variant="login"
              style={{ width: '100%' }}
            >
              Sign in
            </Button>
          </Cell>
        </Grid>
      </form>
    )
  }
