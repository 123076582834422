import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { LoginForm } from '@/components/auth/forms/LoginForm'
import { FullScreenLoader } from '@/components/core/Loader/FullScreenLoader'
import Text from '@/components/core/Text/Text'
import Cell from '@/components/layout/Grid/Cell.Styled'
import Grid from '@/components/layout/Grid/Grid.Styled'
import Layout from '@/components/layout/Layout'
import { LoginLayout } from '@/components/layout/Layout.Styled'
import { useAuth } from '@/hooks/useAuthProvider'

const Index = (): JSX.Element => {
  const { auth, loading } = useAuth()
  const router = useRouter()
  const [isFirstMountLoadingDone, setIsFirstMountLoadingDone] = useState(false)

  useEffect(() => {
    if (auth) {
      if (router.query && router.query.from) {
        router.push(router.query.from as string)
      } else {
        router.push('/users')
      }
    }
  }, [router, auth, loading])

  useEffect(() => {
    if (!auth && !loading) {
      localStorage?.clear()
    }
  }, [auth, loading])

  useEffect(() => {
    if (!loading) {
      setIsFirstMountLoadingDone(true)
    }
  }, [loading])

  return auth === null && isFirstMountLoadingDone ? (
    <Layout>
      <LoginLayout>
        <Grid columns={2} style={{ width: '100%', height: '100vh' }}>
          <Cell
            style={{
              backgroundImage: 'url(/assets/images/login.bg.webp)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Cell>
            <div
              style={{
                maxWidth: '650px',
                display: 'flex',
                margin: '50px auto',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Text variant={'h1'} color={'Black'} as={'h1'}>
                Sign in
              </Text>
              <LoginForm />
            </div>
          </Cell>
        </Grid>
      </LoginLayout>
    </Layout>
  ) : (
    <FullScreenLoader isLoading={loading} />
  )
}

export default Index
